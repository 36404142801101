import React, { createRef, useMemo } from 'react';
import style from './style.module.less';
import { NavLink } from 'react-router-dom';
import LogoImage from '../../assets/logo-blue.png';
import { useTranslation } from 'react-i18next';
import { SOCIAL_MEDIA_LINKS } from '../../constants';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { AuthModal, AuthModalRef } from '../modals';
import { getLibraryPathWithCountry } from '../../utils/get-library-path-with-country';
import { CountryLanguageSelector } from '../country-language-selector';

interface Props {
  className?: string;
}

export const Footer: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const { isAuthenticated } = useKindeAuth();
  const { t, i18n } = useTranslation();
  const authModalRef = createRef<AuthModalRef>();
  const language = i18n.language;

  const SocialContainer = useMemo(
    () => (
      <div className={style.socialContainer}>
        {SOCIAL_MEDIA_LINKS.map((link, index) => (
          <a
            key={index}
            href={link.link}
            target='_blank'
            rel='noreferrer'
            className={style.socialIcon}
          >
            <link.icon className={style.socialIcon} />
          </a>
        ))}
      </div>
    ),
    []
  );

  const termsPrivacyText: string = useMemo(() => {
    return t('auth.copyright-terms-privacy')
      .replace(/TERMS_LINK/g, '/terms')
      .replace(/PRIVACY_LINK/g, '/privacy');
  }, [t]);

  return (
    <>
      <div className={`${style.footer} ${className}`}>
        <div className={style.content}>
          <div className={style.logoColumn}>
            <div className={style.logo}>
              <img src={LogoImage} alt='LawConnect' loading='lazy' />
            </div>
          </div>
          <div className={style.column}>
            <div className={`${style.column} ${style.mainMenu}`}>
              <NavLink
                to='/'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
              >
                {t('pages.home')}
              </NavLink>

              <NavLink
                to='/about'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
              >
                {t('pages.about')}
              </NavLink>
              <NavLink
                to={getLibraryPathWithCountry()}
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
              >
                {t('pages.library')}
              </NavLink>
              <NavLink
                to='/account'
                className={({ isActive }) =>
                  `${style.menuItem} ${isActive ? style.active : ''}`
                }
                onClick={(e) => {
                  if (!isAuthenticated) {
                    e.preventDefault();
                    authModalRef.current?.openModal();
                  }
                }}
              >
                {t('pages.account')}
              </NavLink>
            </div>
          </div>
          <div className={`${style.column} ${style.mainMenu}`}>
            <NavLink
              to='/sailing'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {t('pages.sailing')}
            </NavLink>
            <NavLink
              to='/help'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {t('pages.help')}
            </NavLink>
            <NavLink
              to='/contact-us'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {t('pages.contact-us')}
            </NavLink>
          </div>
          <div className={style.info}>
            {/* <div className={style.addressLabel}>{t('pages.address')}</div>
            <div className={style.address}>
              Level 8, 207 Kent Street
              <br />
              Sydney, NSW
              <br />
              Australia, 2000
              <br />
            </div> */}
            <div className={style.socialContainerLarge}>{SocialContainer}</div>
            <div className={style.languageSelectorLarge}>
              <CountryLanguageSelector />
            </div>
          </div>
        </div>
        <div className={style.bottomBarContainer}>
          <div className={style.socialContainerSmall}>{SocialContainer}</div>
          <div className={style.languageSelectorSmall}>
            <CountryLanguageSelector />
          </div>
          <div className={style.copyright}>
            <div>
              {t('auth.copyright').replace(
                '<YEAR>',
                new Date().getFullYear().toString()
              )}
            </div>
            <div className={style.copyrightBottom}>
              <div>{t('auth.copyright-description')}&nbsp;</div>
              <div dangerouslySetInnerHTML={{ __html: termsPrivacyText }} />
            </div>
          </div>
        </div>
      </div>
      <AuthModal ref={authModalRef} />
    </>
  );
};

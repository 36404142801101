import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import style from './share.module.less';
import Logo from '../../assets/logo-white.png';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { toast } from 'react-toastify';
import LinkIcon from '../../assets/icons/link.svg?react';

import FaceBookIcon from '../../assets/icons/social/share-facebook.svg?react';
import LinkedInIcon from '../../assets/icons/social/share-linkedin.svg?react';
import RedditIcon from '../../assets/icons/social/share-reddit.svg?react';
import ThreadsIcon from '../../assets/icons/social/share-threads.svg?react';
import XIcon from '../../assets/icons/social/share-x.svg?react';
import { usePrematterTranslations } from '../../hooks/use-prematter-translations';
import { Carousel } from '@law-connect/react-components';
import { isTouchDevice, useMobile } from '../../hooks/use-is-mobile';
import env from '../../constants/env';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { actions } from '../../redux/slices';
import { LoadingSpinner } from '../loading-spinner';

export interface ShareModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  onClose?: () => void;
  language: string;
  id: string;
}

export const ShareModal = forwardRef<ShareModalRef | null, Props>(
  (props, ref) => {
    const { onClose, language, id } = props;
    const t = usePrematterTranslations(language);
    const isMobile = useMobile();
    const [open, setOpen] = useState(false);
    const [isInDiv, setIsInDiv] = useState(false);
    const onMouseEnter = useCallback(() => setIsInDiv(true), []);
    const onMouseLeave = useCallback(() => setIsInDiv(false), []);
    const isTabletOrMobile = isTouchDevice();

    const modalRef = createRef<HTMLDivElement>();
    const dispatch = useAppDispatch();

    const closeModal = useCallback(() => {
      setOpen(false);
      onClose && onClose();
    }, [onClose]);
    const openModal = useCallback(() => setOpen(true), []);
    useOnClickOutside(modalRef, closeModal);

    const shareUrl = useMemo(() => `${env.PUBLIC_URL}/share/${id}`, [id]);
    const shortenedUrl = useAppSelector(selectors.user.getShortenUrl(shareUrl));
    const isFetchingShortenUrl = useAppSelector(
      selectors.user.isFetchingShortenUrl()
    );
    const shortenUrlError = useAppSelector(selectors.user.getShortenUrlError());

    const SOCIAL_MEDIA_CLICK = useMemo(
      () => [
        {
          icon: FaceBookIcon,
          name: 'Facebook',
          url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            shortenedUrl
          )}`,
        },
        {
          icon: XIcon,
          name: 'X',
          url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shortenedUrl
          )}`,
        },
        {
          icon: LinkedInIcon,
          name: 'LinkedIn',
          url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shortenedUrl
          )}`,
        },
        {
          icon: RedditIcon,
          name: 'Reddit',
          url: `https://www.reddit.com/submit?url=${encodeURIComponent(
            shortenedUrl
          )}`,
        },
        {
          icon: ThreadsIcon,
          name: 'Threads',
          url: `https://www.threads.net/intent/post?url=${encodeURIComponent(
            shortenedUrl
          )}`,
        },
      ],
      [shortenedUrl]
    );

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    const copyShareUrl = useCallback(() => {
      toast.success(t('toast.link-copied'), {
        icon: <LinkIcon className={style.toastLinkIcon} />,
      });
      navigator.clipboard.writeText(shortenedUrl);
    }, [shortenedUrl, t]);

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const getCarouselItems = useCallback(() => {
      return SOCIAL_MEDIA_CLICK.map((item) => (
        <a
          href={item.url}
          target='_blank'
          className={style.otherWaysButton}
          key={item.name}
        >
          <item.icon />
          <div className={style.otherWaysText}>{item.name}</div>
        </a>
      ));
    }, [SOCIAL_MEDIA_CLICK]);

    useEffect(() => {
      if (
        id &&
        !isFetchingShortenUrl &&
        !shortenedUrl &&
        !shortenUrlError &&
        open
      ) {
        dispatch(
          actions.user.getShortenUrl({ url: `${env.PUBLIC_URL}/share/${id}` })
        );
      }
    }, [
      id,
      dispatch,
      isFetchingShortenUrl,
      shortenedUrl,
      shortenUrlError,
      open,
    ]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <div className={style.closeIconContainer} onClick={closeModal}>
                <CloseIcon className={style.closeIcon} />
              </div>
              <div className={style.header}>
                <img src={'/standard-bg.jpg'} className={style.bgImage} />
                <div className={style.logoWrapper}>
                  <img src={Logo} className={style.logoImage} />
                </div>
                <div className={style.headerDescription}>
                  {t('prematter.share-report-description')}
                </div>
              </div>
            </div>
            <div className={style.shareBody}>
              <div className={style.bodyWrapper}>
                <div className={style.urlLabel}>
                  <span className={style.labelText}>{t('prematter.url')}</span>
                  <div className={style.urlInputContainer}>
                    <input
                      type='text'
                      className={style.urlInput}
                      readOnly
                      value={shortenedUrl}
                      onClick={copyShareUrl}
                    />
                    {isFetchingShortenUrl && (
                      <div className={style.loadingSpinnerContainer}>
                        <LoadingSpinner iconClassName={style.loadingSpinner} />
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.linkButtonContainer}>
                  <button className={style.linkButton} onClick={copyShareUrl}>
                    <LinkIcon className={style.linkIcon} />
                    <span>{t('home.copy-link')}</span>
                  </button>
                </div>
              </div>
              <div
                className={style.otherWaysContainer}
                // className={style.otherWaysButtonsContainer}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <span className={`${style.labelText} ${style.otherWaysLabel}`}>
                  {t('prematter.other-ways')}
                </span>
                <Carousel
                  cardWidth={isMobile ? 90 : 120}
                  // columnCount={cardColumns}
                  items={getCarouselItems()}
                  showProgressBar={true}
                  offsetWidth={isMobile ? 10 : 20}
                  classNames={{
                    container: style.shareCarousel,
                    cardsContainer: style.shareCarouselCardsContainer,
                    actionBar: `${style.shareCarouselActionBar} ${
                      isTabletOrMobile ? '' : style.noTouch
                    } ${isInDiv ? '' : style.outsideDiv}`,
                    arrowButtonContainer: `${style.shareActionButton} ${
                      isTabletOrMobile ? style.noControl : ''
                    }`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ),
      [
        modalRef,
        closeModal,
        t,
        shortenedUrl,
        copyShareUrl,
        onMouseEnter,
        onMouseLeave,
        getCarouselItems,
        isMobile,
        isTabletOrMobile,
        isInDiv,
      ]
    );

    return createPortal(open ? body : <></>, document.body);
  }
);

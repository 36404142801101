import { createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import { User } from '@law-connect/types';


interface UserState {
  pending: {
    fetch?: boolean;
    delete?: boolean;
    update?: boolean;
    shortenUrl?: boolean;
  }
  errors: {
    fetch?: string | null;
    delete?: string | null;
    update?: string | null;
    shortenUrl?: string | null;
  };
  data: User | null;
  shortenUrl: {[originalUrl: string]: string}
}

const userState: UserState = {
  pending: {},
  errors: {},
  data: null,
  shortenUrl: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  // you cant have a reducer name match the thunk action
  reducers: {},
  extraReducers: (builder) => {
    // fetch
    builder.addCase(actions.user.fetch.pending, (state) => {
      state.pending.fetch = true;
      state.errors.fetch = null;
    });
    builder.addCase(actions.user.fetch.fulfilled, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = null;
      state.data = action.payload;
    });
    builder.addCase(actions.user.fetch.rejected, (state, action) => {
      state.pending.fetch = false;
      state.errors.fetch = action.error.message;
    });
    // update
    builder.addCase(actions.user.update.pending, (state) => {
      state.pending.update = true;
      state.errors.update = null;
    });
    builder.addCase(actions.user.update.fulfilled, (state, action) => {
      state.pending.update = false;
      state.errors.update = null;
      state.data = action.payload;
    });
    builder.addCase(actions.user.update.rejected, (state, action) => {
      state.pending.update = false;
      state.errors.update = action.error.message;
    });
    // delete
    builder.addCase(actions.user.delete.pending, (state) => {
      state.pending.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(actions.user.delete.fulfilled, (state) => {
      state.pending.delete = false;
      state.errors.delete = null;
    });
    builder.addCase(actions.user.delete.rejected, (state, action) => {
      state.pending.delete = false;
      state.errors.delete = action.error.message;
    });

    builder.addCase(actions.user.getShortenUrl.pending, (state) => {
      state.pending.shortenUrl = true;
      state.errors.shortenUrl = null;
    });
    builder.addCase(actions.user.getShortenUrl.fulfilled, (state, action) => {
      state.pending.shortenUrl = false;
      state.errors.shortenUrl = null;
      state.shortenUrl[action.meta.arg.url] = action.payload;
    });
    builder.addCase(actions.user.getShortenUrl.rejected, (state, action) => {
      state.pending.shortenUrl = false;
      state.errors.shortenUrl = action.error.message;
    });
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import style from './style.module.less';
import { AuthModal, AuthModalRef } from '../modals';
import HamburgerIcon from '../../assets/icons/hamburger.svg?react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { AUTH_MODAL_PORTAL_ID } from '../../constants';
import { LogoImageComponent } from './logo';
import { CloseIconComponent } from './close';
import { WidthType, useScreenSize } from '../../hooks/use-is-mobile';
import { usePrevious } from '../../hooks/use-previous';
import { createPortal } from 'react-dom';
import selectors from '../../redux/selectors';
import { UserAvatar } from './avatar';
import { getLibraryPathWithCountry } from '../../utils/get-library-path-with-country';
import logoIconImage from '../../assets/logo-icon.png';
import { useAppSelector } from '../../redux/hooks';

export const MENU_HEADER_ID = 'MENU_HEADER_ID';
export const MENU_CONTENT_ID = 'MENU_CONTENT_ID';

interface Props {
  className?: string;
}

export const Header: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const [hoveringUser, setHoveringUser] = useState(false);
  const { isAuthenticated } = useKindeAuth();
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useAppSelector(selectors.user.getUser());
  const prevMenuOpen = usePrevious(isMenuOpen);
  const authModalRef = createRef<AuthModalRef>();
  const menuRef = useRef<HTMLDivElement>(null);
  const screenType = useScreenSize();
  const unreadConnections = useAppSelector(
    selectors.lawyerConnection.getAllUnreadConnections()
  );
  const language = i18n.language;

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openLoginModal: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      closeMenu();
      if (!isAuthenticated) {
        e.preventDefault();
        authModalRef.current?.openModal();
      }
    },
    [authModalRef, isAuthenticated, closeMenu]
  );

  // because we are no longer using the language selector component, we need to handle scroll here
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // on menu open we want to scroll to the top of the page on mobile
  useEffect(() => {
    if (screenType === WidthType.Mobile) {
      if (isMenuOpen && !prevMenuOpen) {
        window.scrollTo(0, 0);
      }
    }
  }, [isMenuOpen, prevMenuOpen, screenType]);

  const BODY = (
    <>
      <div
        ref={menuRef}
        id={MENU_HEADER_ID}
        className={`${style.header} ${className}`}
      >
        <div className={style.content} id={MENU_CONTENT_ID}>
          <LogoImageComponent isMenuOpen={isMenuOpen} menuRef={menuRef} />
          <div
            className={style.hamburger}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <HamburgerIcon />
          </div>
          <NavLink to='/account' onClick={openLoginModal}>
            <div className={style.mobileUserMenu}>
              <UserAvatar imageUrl={user?.picture} />
              {unreadConnections.length > 0 ? (
                <div className={style.badge} />
              ) : null}
            </div>
          </NavLink>
          <CloseIconComponent isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
          <div className={`${style.menu} ${isMenuOpen ? style.show : ''}`}>
            <NavLink
              to='/'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              <div className={style.textWrapper} title={t('pages.home')}>
                {t('pages.home')}
              </div>
            </NavLink>
            {/* <NavLink
              to='/how-it-works'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              {t('pages.how-it-works')}
            </NavLink> */}
            <NavLink
              to='/about'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              <div className={style.textWrapper} title={t('pages.about')}>
                {t('pages.about')}
              </div>
            </NavLink>
            <NavLink
              to={getLibraryPathWithCountry()}
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              <div className={style.textWrapper} title={t('pages.library')}>
                {t('pages.library')}
              </div>
            </NavLink>
            <NavLink
              to='/account'
              onClick={openLoginModal}
              className={({ isActive }) =>
                // eslint-disable-next-line @stylistic/js/max-len
                `${style.menuItem} ${isActive ? style.active : hoveringUser ? style.hovering : ''}`
              }
            >
              {/* we use title to force set the width to bold */}
              <div className={style.textWrapper} title={t('pages.account')}>
                {t('pages.account')}
              </div>
            </NavLink>
            <NavLink to='/account' onClick={openLoginModal}>
              <div
                className={style.user}
                onMouseEnter={() => setHoveringUser(true)}
                onMouseLeave={() => setHoveringUser(false)}
              >
                <UserAvatar imageUrl={user?.picture} />
                {unreadConnections.length > 0 ? (
                  <div className={style.badge} />
                ) : null}
              </div>
            </NavLink>
            <div className={style.mobileSubMenu}>
              <NavLink to='/sailing' className={style.subMenuItem}>
                {t('pages.sailing')}
              </NavLink>
              <NavLink to='/help' className={style.subMenuItem}>
                {t('pages.help')}
              </NavLink>
              <NavLink to='/contact-us' className={style.subMenuItem}>
                {t('pages.contact-us')}
              </NavLink>
            </div>
            <div className={style.mobileLogoContainer}>
              <img
                src={logoIconImage}
                alt='lawconnect-icon-background'
                className={style.logoImage}
              />
            </div>

            {/* <div className={style.subMenu}>
              <LanguageSelector isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
              <a className={style.title} href={CONTACT_US_LINK}>
                {t('pages.contact-us')}
              </a>
            </div> */}
          </div>
        </div>
        <AuthModal ref={authModalRef} />
      </div>
    </>
  );

  if (document.getElementById(AUTH_MODAL_PORTAL_ID)) {
    return createPortal(BODY, document.getElementById(AUTH_MODAL_PORTAL_ID));
  } else {
    return BODY;
  }
};

import { getI18n } from 'react-i18next';

export const getLibraryPathWithCountry = (
  path?: string,
  usePathMatch?: boolean
) => {
  const pathMatch = window.location.pathname.match(/\/([a-z]{2}-[a-z]{2})\//i);
  const languageCode = pathMatch && usePathMatch
    ? pathMatch[1]
    : getI18n().language || navigator.language;

  return (
    `/${languageCode}/library` +
    (path?.trim().length > 0 ? (path[0] === '/' ? path : `/${path}`) : '')
  );
};

import React from 'react';
import styles from './style.module.less';
import LoadingSvg from '../../assets/icons/loading.svg?react';

interface Props {
  className?: string;
  iconClassName?: string;
}

export const LoadingSpinner: React.FC<Props> = (props) => {
  const { className, iconClassName } = props;
  return (
    <div className={className}>
      <LoadingSvg className={`${styles.loadingIcon} ${iconClassName ?? ''}`} />
    </div>
  );
};

import {
  ListenerEffectAPI,
  PayloadAction,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import { actions } from '../slices';
import { AppDispatch, RootState } from '../store';
import selectors from '../selectors';
// for demo purposes we want to fetch every 30 seconds and later we can change this to 1 minute
const DELAY_COUNT = 20000; 

// we want to keep fetching connections to see if a new one arrives
const lawyerConnectionMiddleware = createListenerMiddleware();

let timer: NodeJS.Timeout | null = null;

lawyerConnectionMiddleware.startListening({
  actionCreator: actions.lawyerConnection.startPollConnections,
  effect: async (
    action: PayloadAction<unknown>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>
  ) => {
    const state = listenerApi.getState() as RootState;
    const isPolling = selectors.lawyerConnection.isPollingConnections()(state);
    if (isPolling) {
      return;
    }
    // we want to set the polling to be true
    listenerApi.dispatch(
      actions.lawyerConnection.startPollConnectionsFinished(null)
    );

    try {
      // every few seconds we want to dispatch the fetch connections action
      if (timer) {
        clearTimeout(timer);
      }
      listenerApi.dispatch(actions.lawyerConnection.fetchAll());
      timer = setInterval(() => {
        listenerApi.dispatch(actions.lawyerConnection.fetchAll());
      }, DELAY_COUNT);
    } catch (error) {
      console.error('Error polling connections', error);
      listenerApi.dispatch(actions.lawyerConnection.stopPollConnections(null));
    }
  },
});

lawyerConnectionMiddleware.startListening({
  actionCreator: actions.lawyerConnection.stopPollConnections,
  effect: async (
    action: PayloadAction<unknown>,
    listenerApi: ListenerEffectAPI<RootState, AppDispatch>
  ) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
  },
});

export default lawyerConnectionMiddleware;
